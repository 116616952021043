import React from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';
import ethereumIcon from '../../assets/wp-content/themes/cosmic/img/ethereum.svg';
import injectiveIcon from '../../assets/wp-content/themes/cosmic/img/injective.svg';
import dydxIcon from '../../assets/wp-content/themes/cosmic/img/dydx.svg';
import kujiraIcon from '../../assets/wp-content/themes/cosmic/img/kujira.png';
import neutronIcon from '../../assets/wp-content/themes/cosmic/img/neutron.svg';
import strideIcon from '../../assets/wp-content/themes/cosmic/img/stride.svg';

const Section4 = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-huge avia-no-border-styling  avia-full-stretch avia-bg-style-scroll  avia-builder-el-25  el_after_av_section  el_before_av_section   container_wrap fullsize"
            data-section-bg-repeat="stretch"
            id="validator">
            <div className="container">
                <div className="template-page content  av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-15">
                        <div className="entry-content-wrapper clearfix">
                            <div
                                className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-26  avia-builder-el-no-sibling  "
                                style={{ borderRadius: 0 }}>
                                <div
                                    className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-27  el_before_av_codeblock  avia-builder-el-first  "
                                    style={{
                                        paddingBottom: '10px',
                                        marginBottom: '4%',
                                    }}>
                                    <h1 className="av-special-heading-tag ">{variables[props.lang]['section3_heading']}</h1>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <section className="avia_codeblock_section  avia_code_block_4">
                                    <div className="avia_codeblock ">
                                        <div className="index-grid">
                                            <div className="index">
                                                <img
                                                    alt="Cosmos Network"
                                                    height="54" src="./wp-content/themes/cosmic/img/cosmos.svg"
                                                    width="54"/>
                                                <p>{variables[props.lang].cosmos}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="Akash" height="54"
                                                    src="./wp-content/themes/cosmic/img/akash.svg" width="54"/>
                                                <p>{variables[props.lang].akash}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="Sentinel Network"
                                                    height="54" src="./wp-content/themes/cosmic/img/sentinel.svg"
                                                    width="54"/>
                                                <p>{variables[props.lang].sentinel}</p>
                                            </div>
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="E-Money" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/e-money.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang]['e_money']}</p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*    <img */}
                                            {/*        alt="Terra" height="54" */}
                                            {/*        src="./wp-content/themes/cosmic/img/terra.svg" width="54"/> */}
                                            {/*    <p>{variables[props.lang].terra}</p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*    <img */}
                                            {/*        alt="Kusama" height="54" */}
                                            {/*        src="./wp-content/themes/cosmic/img/kusama.svg" width="54"/> */}
                                            {/*    <p>{variables[props.lang].kusama}</p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*    <img */}
                                            {/*        alt="Osmosis" height="54" */}
                                            {/*        src="./wp-content/themes/cosmic/img/osmosis.svg" width="54"/> */}
                                            {/*    <p>{variables[props.lang].osmosis}</p> */}
                                            {/* </div> */}
                                            <div className="index">
                                                <img
                                                    alt="KI" height="54" src="./wp-content/themes/cosmic/img/ki.svg"
                                                    width="54"/>
                                                <p>{variables[props.lang].k1}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="Persistence" height="54"
                                                    src="./wp-content/themes/cosmic/img/persistence.svg" width="54"/>
                                                <p>{variables[props.lang].persistence}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="Juno" height="54"
                                                    src="./wp-content/themes/cosmic/img/juno.svg" width="54"/>
                                                <p>{variables[props.lang].juno}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="omniflix network" height="54"
                                                    src="./wp-content/themes/cosmic/img/omniflix.svg" width="54"/>
                                                <p>{variables[props.lang].omniflix}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="asset mantle" height="54"
                                                    src="./wp-content/themes/cosmic/img/asset_mantle.svg" width="54"/>
                                                <p>{variables[props.lang]['asset_mantle']}</p>
                                            </div>
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="comdex" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/comdex.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].comdex}</p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="ixo" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/ixo.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].ixo}</p> */}
                                            {/* </div> */}
                                            <div className="index">
                                                <img
                                                    alt="TGrade" height="54"
                                                    src="./wp-content/themes/cosmic/img/grade.svg" width="54"/>
                                                <p>{variables[props.lang].tgrade}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="injective"
                                                    height="54" src={injectiveIcon}
                                                    style={{ width: '54px', height: '54px' }} width="54"/>
                                                <p>{variables[props.lang].injective}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="dydx"
                                                    height="54" src={dydxIcon}
                                                    style={{ width: '54px', height: '54px' }} width="54"/>
                                                <p>{variables[props.lang].dydx}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="kujira"
                                                    height="54" src={kujiraIcon}
                                                    style={{ width: '54px', height: '54px' }} width="54"/>
                                                <p>{variables[props.lang].kujira}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="neutron"
                                                    height="54" src={neutronIcon}
                                                    style={{ width: '44px', height: '44px' }} width="54"/>
                                                <p>{variables[props.lang].neutron}</p>
                                            </div>
                                            <div className="index">
                                                <img
                                                    alt="stride"
                                                    height="54" src={strideIcon}
                                                    style={{ width: '54px', height: '54px' }} width="54"/>
                                                <p>{variables[props.lang].stride}</p>
                                            </div>
                                            {/* <div className="index"> */}
                                            {/*    <img */}
                                            {/*        alt="Polkadex" height="50" */}
                                            {/*        src="./wp-content/themes/cosmic/img/polkadex.svg" width="50"/> */}
                                            {/*    <p>{variables[props.lang].polkadex}</p> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </section>
                                <section className="avia_codeblock_section  avia_code_block_5">
                                    <div className="avia_codeblock ">
                                        <div className="index-grid-2">
                                            <div className="index">
                                                <img
                                                    alt="ethereum"
                                                    height="50" src={ethereumIcon}
                                                    style={{ width: '54px', height: '54px' }} width="50"/>
                                                <p>{variables[props.lang].ethereum_2}<span>{variables[props.lang]['coming_soon']}</span></p>
                                            </div>
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="BSC Projects" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/bsc.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].bsc}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*    <img */}
                                            {/*        alt="Polkadot" height="54" */}
                                            {/*        src="./wp-content/themes/cosmic/img/polkadot.svg" width="54"/> */}
                                            {/*    <p>{variables[props.lang].polkadot}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="Near" height="54" src="./wp-content/themes/cosmic/img/near.svg" */}
                                            {/*         width="54"/> */}
                                            {/*     <p>{variables[props.lang].near}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="Agoric" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/agoric.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].agoric}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="evmos" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/evmos.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].evmos}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="osmosis" height="50" */}
                                            {/*         src="./wp-content/themes/cosmic/img/osmosis.svg" width="50"/> */}
                                            {/*     <p>{variables[props.lang].osmosis}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="quicksilver" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/quickSilver.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].quicksilver}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="gnoland" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/gnoland.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang]['gno_land']}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                            {/* <div className="index"> */}
                                            {/*     <img */}
                                            {/*         alt="regen" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/regen.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].regen}<span>{variables[props.lang]['coming_soon']}</span></p> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Section4.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Section4;
