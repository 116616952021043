import { combineReducers } from 'redux';
import language from './language';
import navBar from './navBar';
import contact from './contact';
import snackbar from './snackbar';
import validators from './validators';
import player from './player';

export default combineReducers({
    language,
    navBar,
    contact,
    snackbar,
    validators,
    player,
});
