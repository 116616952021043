import React from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';
import background from '../../assets/wp-content/uploads/2021/06/cosmos-bg.jpg';

const Teams = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-60  el_after_av_section  el_before_av_layout_row   container_wrap fullsize teams_section"
            data-section-bg-repeat="no-repeat"
            style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundAttachment: 'scroll',
            }}>
            <div className="av-layout-grid-container entry-content-wrapper main_color av-flex-cells av-break-at-tablet    avia-builder-el-67  el_after_av_section  el_before_av_one_full  submenu-not-first container_wrap fullsize" id="team">
                <div className="flex_cell no_margin av_two_fifth  avia-builder-el-68  el_before_av_cell_three_fifth  avia-builder-el-first   ">
                    <div className="flex_cell_inner">
                        <div className="flex_column_table av-equal-height-column-flextable av-break-at-tablet-flextable">
                            <div className="flex_column av_one_full  av-animated-generic bottom-to-top  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-69  avia-builder-el-no-sibling  " style={{ borderRadius: 0 }}>
                                <div className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-70  el_before_av_textblock  avia-builder-el-first  " style={{ paddingBottom: '10px' }}>
                                    <h1 className="av-special-heading-tag ">{variables[props.lang]['section7_heading']}</h1>
                                    <div className="special-heading-border"><div className="special-heading-inner-border"/></div>
                                </div>
                                <section className="av_textblock_section "><div className="avia_textblock  ">
                                    <p style={{ textAlign: 'center' }}>{variables[props.lang]['section7_content']}</p>
                                </div></section>
                            </div></div>
                    </div></div>
                <div className="flex_cell no_margin av_three_fifth  avia-builder-el-72  el_after_av_cell_two_fifth  avia-builder-el-last  team-members  av-zero-padding " style={{ verticalAlign: 'top', padding: 0 }}><div className="flex_cell_inner">
                    <div className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-73  el_before_av_one_third  avia-builder-el-first  " style={{ borderRadius: 0 }}>
                        <div className="avia-image-container  av-styling-    avia-builder-el-74  el_before_av_textblock  avia-builder-el-first  avia-align-center "><div className="avia-image-container-inner"><div className="avia-image-overlay-wrap"><img alt="" className="wp-image-54 avia-img-lazy-loading-not-54 avia_image" height="170" src="./wp-content/uploads/2021/06/hector.png" title="hector" width="170"/></div></div></div>
                        <section className="av_textblock_section "><div className="avia_textblock  ">
                            <div className="team-description">
                                <p style={{ textAlign: 'center' }}><strong>{variables[props.lang]['s7_card1_name']}</strong></p>
                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['s7_card1_content']}</p>
                            </div>
                        </div></section>
                    </div>
                    <div className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-76  el_after_av_one_third  el_before_av_one_third  " style={{ borderRadius: 0 }}>
                        <div className="avia-image-container  av-styling-    avia-builder-el-77  el_before_av_textblock  avia-builder-el-first  avia-align-center "><div className="avia-image-container-inner"><div className="avia-image-overlay-wrap"><img alt="" className="wp-image-55 avia-img-lazy-loading-not-55 avia_image" height="170" src="./wp-content/uploads/2021/06/eric-su.png" title="eric-su" width="170"/></div></div></div>
                        <section className="av_textblock_section "><div className="avia_textblock  ">
                            <div className="team-description">
                                <p style={{ textAlign: 'center' }}><strong>{variables[props.lang]['s7_card2_name']}</strong></p>
                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['s7_card2_content']}</p>
                            </div>
                        </div></section>
                    </div>
                    <div className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-79  el_after_av_one_third  el_before_av_one_full  " style={{ borderRadius: 0 }}>
                        <div className="avia-image-container  av-styling-    avia-builder-el-80  el_before_av_textblock  avia-builder-el-first  avia-align-center ">
                            <div className="avia-image-container-inner">
                                <div className="avia-image-overlay-wrap">
                                    <img alt="" className="wp-image-56 avia-img-lazy-loading-not-56 avia_image" height="170" src="./wp-content/uploads/2021/06/cosmic-tech-labs.png" title="cosmic-tech-labs" width="170"/>
                                </div></div></div>
                        <section className="av_textblock_section "><div className="avia_textblock  ">
                            <div className="team-description">
                                <p style={{ textAlign: 'center' }}><strong>{variables[props.lang]['s7_card3_name']}</strong></p>
                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['s7_card3_position']}<br />
                                    {variables[props.lang]['s7_card3_content']}</p>
                            </div>
                        </div></section>
                    </div>
                    <div className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-76  el_after_av_one_third  el_before_av_one_third  " style={{ borderRadius: 0 }}>
                        <div className="avia-image-container  av-styling-    avia-builder-el-77  el_before_av_textblock  avia-builder-el-first  avia-align-center "><div className="avia-image-container-inner"><div className="avia-image-overlay-wrap"><img alt="" className="wp-image-55 avia-img-lazy-loading-not-55 avia_image" height="170" src="./wp-content/uploads/2021/06/estelle.png" title={variables[props.lang]['s7_card4_name']} width="170"/></div></div></div>
                        <section className="av_textblock_section "><div className="avia_textblock  ">
                            <div className="team-description">
                                <p style={{ textAlign: 'center' }}><strong>{variables[props.lang]['s7_card4_name']}</strong></p>
                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['s7_card4_content']}</p>
                            </div>
                        </div></section>
                    </div>
                    <div className="flex_column av_one_third last av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-76  el_after_av_one_third  el_before_av_one_third  " style={{ borderRadius: 0 }}>
                        <div className="avia-image-container  av-styling-    avia-builder-el-77  el_before_av_textblock  avia-builder-el-first  avia-align-center "><div className="avia-image-container-inner"><div className="avia-image-overlay-wrap"><img alt="" className="wp-image-55 avia-img-lazy-loading-not-55 avia_image" height="170" src="./wp-content/uploads/2021/06/anita.png" title={variables[props.lang]['s7_card5_name']} width="170"/></div></div></div>
                        <section className="av_textblock_section "><div className="avia_textblock  ">
                            <div className="team-description">
                                <p style={{ textAlign: 'center' }}><strong>{variables[props.lang]['s7_card5_name']}</strong></p>
                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['s7_card5_content']}</p>
                            </div>
                        </div></section>
                    </div>
                    <div className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-82  el_after_av_one_third  avia-builder-el-last  column-top-margin" style={{ borderRadius: 0 }}/>
                </div></div>
            </div>
            <div className="main_color av_default_container_wrap container_wrap fullsize" id="after_submenu_1"><div className="container">
                <div className="template-page content  av-content-full alpha units"><div className="post-entry post-entry-type-page post-entry-15"><div className="entry-content-wrapper clearfix">
                    <div className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-83  el_after_av_layout_row  el_before_av_section  avia-builder-el-no-sibling  " id="partners" style={{ borderRadius: 0 }}>
                        {/* <section className="avia_codeblock_section  avia_code_block_9"><div className="avia_codeblock "><div className="powered"> */}
                        {/*    <div><img alt="Powered by Akash" height="32" src="./wp-content/themes/cosmic/img/powered.svg" width="260" /></div> */}
                        {/* </div></div></section> */}
                        <section className="avia_codeblock_section  avia_code_block_10"><div className="avia_codeblock "><div className="partners">
                            <h2>{variables[props.lang].partners}</h2>
                            <div className="logos"><img alt="ExNetwork" height="60" src="./wp-content/themes/cosmic/img/ex-logo.png" width="260" /></div>
                            <div className="logos"><img alt="Omniflix Network" height="60" src="./wp-content/themes/cosmic/img/omni-logo.svg" width="366" /></div>
                        </div></div></section>
                    </div>
                </div></div></div>
            </div></div>
        </div>
    );
};

Teams.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Teams;
