import React, { Component } from 'react';
import Contact from './Contact';
import Footer from './Footer.js';
import './index.css';
import Roadmap from './Roadmap.js';
import Section1 from './Section1.js';
import Section2 from './Section2.js';
import Section3 from './Section3.js';
import Section4 from './Section4.js';
import Section5 from './Section5.js';
import Section6 from './Section6.js';
import Section7 from './Section7.js';
// import Section8 from './Section8.js';
// import Section9 from './Section9.js';
import Teams from './Teams.js';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchValidatorStats } from '../../actions/validators';
import YouTubePlayer from './YouTubePlayer';
import { fetchVideoList } from '../../actions/player';

class Home extends Component {
    constructor (props) {
        super(props);

        this.state = {
            timer: null,
        };
    }

    componentDidMount () {
        if (this.props.videos && !this.props.videos.length && !this.props.videosInProgress) {
            this.props.fetchVideoList();
        }

        if (Object.keys(this.props.validatorStats).length || this.props.inProgress) {
            return;
        }

        this.props.fetchValidatorStats();

        const time = setInterval(() => {
            this.props.fetchValidatorStats();
        }, 60000);
        this.setState({
            timer: time,
        });
    }

    componentWillUnmount () {
        clearInterval(this.state.timer);
    }

    render () {
        return (
            <div className="all_colors" data-scroll-offset="88" id="main">
                <Section1 lang={this.props.lang}/>
                <Section2/>
                <YouTubePlayer lang={this.props.lang} list={this.props.videos}/>
                <Section3 lang={this.props.lang}/>
                <Section4 lang={this.props.lang}/>
                <Section5 lang={this.props.lang}/>
                <Section6 lang={this.props.lang}/>
                <Section7 lang={this.props.lang}/>
                {/* <Section8 lang={this.props.lang}/> */}
                {/* <Section9 lang={this.props.lang}/> */}
                <Teams lang={this.props.lang}/>
                <Roadmap lang={this.props.lang}/>
                <Contact lang={this.props.lang}/>
                <Footer lang={this.props.lang}/>
            </div>
        );
    }
}

Home.propTypes = {
    fetchValidatorStats: PropTypes.func.isRequired,
    fetchVideoList: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    validatorStats: PropTypes.object.isRequired,
    videos: PropTypes.array.isRequired,
    videosInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        validatorStats: state.validators.stats.value,
        videos: state.player.videos.value,
        videosInProgress: state.player.videos.inProgress,
        inProgress: state.validators.stats.inProgress,
    };
};

const actionToProps = {
    fetchValidatorStats,
    fetchVideoList,
};

export default connect(stateToProps, actionToProps)(Home);
