import React from 'react';
import logo from '../../assets/wp-content/uploads/2021/06/logo.png';
import './index.css';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const NavBar = (props) => {
    return (
        <header
            className="all_colors header_color dark_bg_color  av_header_top av_logo_left av_main_nav_header av_menu_right av_slim av_header_sticky av_header_shrinking av_header_stretch_disabled av_mobile_menu_phone av_header_transparency av_header_searchicon_disabled av_header_unstick_top_disabled av_bottom_nav_disabled  av_header_border_disabled"
            id="header"
            itemScope="itemscope" itemType="https://schema.org/WPHeader" role="banner">
            <div className="container_wrap container_wrap_logo" id="header_main">
                <div className="container av-logo-container">
                    <div className="inner-container">
                        <span className="logo">
                            <a href="./index.html">
                                <img alt="Cosmic Validator" height="100" src={logo} title="" width="300"/>
                            </a>
                        </span>
                        <nav
                            className="main_menu" data-selectname="Select a page" itemScope="itemscope"
                            itemType="https://schema.org/SiteNavigationElement" role="navigation">
                            <div className="avia-menu av-main-nav-wrap">
                                <ul className="menu av-main-nav" id="avia-menu">
                                    <li
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-15 current_page_item menu-item-top-level menu-item-top-level-1"
                                        id="menu-item-100">
                                        <a href="./index.html" itemProp="url">
                                            <span className="avia-bullet"/>
                                            <span className="avia-menu-text">{variables[props.lang].home}</span>
                                            <span className="avia-menu-fx">
                                                <span className="avia-arrow-wrap">
                                                    <span className="avia-arrow"/>
                                                </span>
                                            </span>
                                        </a></li>
                                    {/* <li */}
                                    {/*    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-top-level menu-item-top-level-2" */}
                                    {/*    id="menu-item-101"> */}
                                    {/*    <a className="coming_soon" href="#" itemProp="url"> */}
                                    {/*        <span className="avia-bullet"/> */}
                                    {/*        <span className="avia-menu-text"> */}
                                    {/*            {variables[props.lang]['cosmic_compass_explorer']}&nbsp; */}
                                    {/*            <span>({variables[props.lang]['coming_soon']})</span> */}
                                    {/*        </span> */}
                                    {/*        <span className="avia-menu-fx"> */}
                                    {/*            <span className="avia-arrow-wrap"> */}
                                    {/*                <span className="avia-arrow"/> */}
                                    {/*            </span> */}
                                    {/*        </span> */}
                                    {/*    </a></li> */}
                                    <li
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-top-level menu-item-top-level-3"
                                        id="menu-item-102">
                                        <a
                                            href="https://medium.com/cosmicvalidator"
                                            itemProp="url"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <span className="avia-bullet"/>
                                            <span className="avia-menu-text">{variables[props.lang].blog}</span>
                                            <span className="avia-menu-fx">
                                                <span className="avia-arrow-wrap">
                                                    <span className="avia-arrow"/>
                                                </span>
                                            </span>
                                        </a></li>
                                    <li
                                        className="menu-item menu-item-type-custom menu-item-object-custom av-menu-button av-menu-button-bordered menu-item-top-level menu-item-top-level-4"
                                        id="menu-item-103">
                                        <a href="#validator" itemProp="url">
                                            <span className="avia-bullet"/>
                                            <span className="avia-menu-text">{variables[props.lang]['stake_with_us']}</span>
                                            <span className="avia-menu-fx">
                                                <span className="avia-arrow-wrap">
                                                    <span className="avia-arrow"/>
                                                </span>
                                            </span>
                                        </a></li>
                                    <li
                                        className="social-menu menu-item menu-item-type-custom menu-item-object-custom av-menu-button av-menu-button-bordered menu-item-top-level menu-item-top-level-5"
                                        id="menu-item-104">
                                        <a
                                            href="https://t.me/CosmicValidator"
                                            itemProp="url"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <span className="avia-bullet"/>
                                            <span className="avia-menu-text">T</span>
                                            <span className="avia-menu-fx">
                                                <span className="avia-arrow-wrap">
                                                    <span className="avia-arrow"/>
                                                </span>
                                            </span>
                                        </a></li>
                                    <li
                                        className="social-menu menu-item menu-item-type-custom menu-item-object-custom av-menu-button av-menu-button-bordered menu-item-top-level menu-item-top-level-6"
                                        id="menu-item-105">
                                        <a
                                            href="https://twitter.com/CosmicValidator"
                                            itemProp="url"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <span className="avia-bullet"/>
                                            <span className="avia-menu-text">T</span>
                                            <span className="avia-menu-fx">
                                                <span className="avia-arrow-wrap">
                                                    <span className="avia-arrow"/>
                                                </span>
                                            </span>
                                        </a></li>
                                    <li
                                        className="social-menu menu-item menu-item-type-custom menu-item-object-custom av-menu-button av-menu-button-bordered menu-item-top-level menu-item-top-level-6"
                                        id="menu-item-106">
                                        <a
                                            href="https://www.youtube.com/@cosmicvalidator?sub_confirmation=1"
                                            itemProp="url"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <span className="avia-bullet"/>
                                            <span className="avia-menu-text">Y</span>
                                            <span className="avia-menu-fx">
                                                <span className="avia-arrow-wrap">
                                                    <span className="avia-arrow"/>
                                                </span>
                                            </span>
                                        </a></li>
                                    <li className="av-burger-menu-main menu-item-avia-special ">
                                        <a aria-hidden="false" aria-label="Menu" href="#">
                                            <span className="av-hamburger av-hamburger--spin av-js-hamburger">
                                                <span className="av-hamburger-box">
                                                    <span className="av-hamburger-inner"/>
                                                    <strong>Menu</strong>
                                                </span>
                                            </span>
                                            <span className="avia_hidden_link_text">{variables[props.lang].menu}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="header_bg"/>
        </header>
    );
};

NavBar.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(NavBar);
