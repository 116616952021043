export const NAME_SET = 'NAME_SET';
export const EMAIL_SET = 'EMAIL_SET';
export const COMMENT_SET = 'COMMENT_SET';

export const SUBSCRIBE_NAME_SET = 'SUBSCRIBE_NAME_SET';
export const SUBSCRIBE_MAIL_SET = 'SUBSCRIBE_MAIL_SET';

export const SUBMIT_CONTACT_DETAILS_IN_PROGRESS = 'SUBMIT_CONTACT_DETAILS_IN_PROGRESS';
export const SUBMIT_CONTACT_DETAILS_SUCCESS = 'SUBMIT_CONTACT_DETAILS_SUCCESS';
export const SUBMIT_CONTACT_DETAILS_ERROR = 'SUBMIT_CONTACT_DETAILS_ERROR';

export const AUTO_HIDE_SET = 'AUTO_HIDE_SET';
