import React from 'react';
import './index.css';

const DotsLoading = () => {
    return (
        <span className="spinner">
            <span className="bounce1"/>
            <span className="bounce2"/>
            <span className="bounce3"/>
        </span>
    );
};

export default DotsLoading;
