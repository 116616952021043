import React from 'react';
import background from '../../assets/wp-content/uploads/2021/06/cta-bg.jpg';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Section3 = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-8  el_after_av_section  el_before_av_section   container_wrap fullsize"
            id="usps">
            <div className="container">
                <div className="template-page content  av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-15">
                        <div className="entry-content-wrapper clearfix">
                            <div
                                className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-9  el_before_av_one_third  avia-builder-el-first  "
                                id="cta-block" style={{
                                    background: `url(${background}) center center no-repeat`,
                                    borderRadius: 0,
                                }}>
                                <div
                                    className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-10  el_before_av_textblock  avia-builder-el-first  "
                                    style={{ paddingBottom: '10px' }}>
                                    <div className="av-subheading av-subheading_above  " style={{ fontSize: '15px' }}>
                                        <p>{variables[props.lang]['section2_sub_heading']}</p>
                                    </div>
                                    <h1 className="av-special-heading-tag ">{variables[props.lang].section2_heading}</h1>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <section className="av_textblock_section ">
                                    <div className="avia_textblock  ">
                                        <p style={{ textAlign: 'center' }}>{variables[props.lang]['section2_content']}</p>
                                    </div>
                                </section>
                                <div
                                    className="avia-buttonrow-wrap avia-buttonrow-center   avia-builder-el-12  el_after_av_textblock  avia-builder-el-last ">
                                    <a
                                        className="avia-button  avia-color-light  avia-icon_select-no avia-size-large "
                                        href="https://medium.com/the-interchain-foundation/icf-delegations-program-meet-the-validators-cycle-2-431f9fc790a8"
                                        rel="noopener noreferrer"
                                        style={{ margin: '0 3px 3px' }} target="_blank"><span className="avia_iconbox_title">{variables[props.lang]['selected_by_icf']}</span></a>
                                    <a
                                        className="avia-button  avia-color-light  avia-icon_select-no avia-size-large "
                                        href="https://commonwealth.im/stride/discussion/13085-redelegate-the-stride-cosmos-hub-validator-set"
                                        rel="noopener noreferrer"
                                        style={{ margin: '0 3px 3px' }} target="_blank"><span className="avia_iconbox_title">{variables[props.lang]['selected_by_stride']}</span></a>
                                </div>
                            </div>
                            <div
                                className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-13  el_after_av_one_full  el_before_av_one_third  column-top-margin"
                                style={{ borderRadius: 0 }}>
                                <section className="avia_codeblock_section  avia_code_block_1">
                                    <div className="avia_codeblock ">
                                        <img alt={variables[props.lang]['section2_card1_heading']} src="./wp-content/themes/cosmic/img/rock-solid.svg"/>
                                    </div>
                                </section>
                                <div
                                    className="av-special-heading av-special-heading-h3  blockquote modern-quote modern-centered  avia-builder-el-15  el_after_av_codeblock  el_before_av_textblock  "
                                    style={{ paddingBottom: '10px' }}>
                                    <h3 className="av-special-heading-tag ">{variables[props.lang]['section2_card1_heading']}</h3>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <section className="av_textblock_section ">
                                    <div className="avia_textblock  ">
                                        <p style={{ textAlign: 'center' }}>{variables[props.lang]['section2_card1_content']}</p>
                                    </div>
                                </section>
                            </div>
                            <div
                                className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-17  el_after_av_one_third  el_before_av_one_third  column-top-margin"
                                style={{ borderRadius: 0 }}>
                                <section className="avia_codeblock_section  avia_code_block_2">
                                    <div className="avia_codeblock ">
                                        <img alt={variables[props.lang]['section2_card2_heading']} src="./wp-content/themes/cosmic/img/safe-and-secure.svg"/>
                                    </div>
                                </section>
                                <div
                                    className="av-special-heading av-special-heading-h3  blockquote modern-quote modern-centered  avia-builder-el-19  el_after_av_codeblock  el_before_av_textblock  "
                                    style={{ paddingBottom: '10px' }}>
                                    <h3 className="av-special-heading-tag ">{variables[props.lang]['section2_card2_heading']}</h3>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <section className="av_textblock_section ">
                                    <div className="avia_textblock  ">
                                        <p style={{ textAlign: 'center' }}>{variables[props.lang]['section2_card2_content']}</p>
                                    </div>
                                </section>
                            </div>
                            <div
                                className="flex_column av_one_third  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-21  el_after_av_one_third  avia-builder-el-last  column-top-margin"
                                style={{ borderRadius: 0 }}>
                                <section className="avia_codeblock_section  avia_code_block_3">
                                    <div className="avia_codeblock ">
                                        <img alt={variables[props.lang]['section2_card3_heading']} src="./wp-content/themes/cosmic/img/real-experts.svg"/></div>
                                </section>
                                <div
                                    className="av-special-heading av-special-heading-h3  blockquote modern-quote modern-centered  avia-builder-el-23  el_after_av_codeblock  el_before_av_textblock  "
                                    style={{ paddingBottom: '10px' }}>
                                    <h3 className="av-special-heading-tag ">{variables[props.lang]['section2_card3_heading']}</h3>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <section className="av_textblock_section ">
                                    <div className="avia_textblock  ">
                                        <p style={{ textAlign: 'center' }}>{variables[props.lang]['section2_card3_content']}</p>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Section3.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Section3;
