import React from 'react';
import logo1 from '../../assets/wp-content/themes/cosmic/img/cosmos.svg';
import logo2 from '../../assets/wp-content/themes/cosmic/img/akash.svg';
import logo3 from '../../assets/wp-content/themes/cosmic/img/sentinel.svg';
// import logo4 from '../../assets/wp-content/themes/cosmic/img/e-money.svg';
import logo5 from '../../assets/wp-content/themes/cosmic/img/ki.svg';
// import logo6 from '../../assets/wp-content/themes/cosmic/img/osmosis.svg';
// import logo7 from '../../assets/wp-content/themes/cosmic/img/kusama.svg';
// import logo8 from '../../assets/wp-content/themes/cosmic/img/terra.svg';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';
import { connect } from 'react-redux';
import DotsLoading from '../../components/DotsLoading';
import { abbreviateNumber } from '../../utils/Numbers';

const Section2 = (props) => {
    const stats = (network) => {
        const filterValue = props.data && props.data.list && props.data.list.length &&
            props.data.list.find((value) => value.network && (value.network['custom_id'] === network));

        return (
            props.inProgress
                ? <DotsLoading/>
                : filterValue && filterValue['total_usd_value']
                    ? <span>${abbreviateNumber(filterValue['total_usd_value'])}</span>
                    : network === 'polkadex'
                        ? <span style={{ textTransform: 'capitalize' }}>coming soon</span>
                        : <span>NA</span>
        );
    };

    return (
        <div
            className="avia-section main_color avia-section-no-padding avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-5  el_after_av_section  el_before_av_section   container_wrap fullsize"
            id="validator">
            <div className="container">
                <div className="template-page content  av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-15">
                        <div className="entry-content-wrapper clearfix">
                            <div
                                className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-6  avia-builder-el-no-sibling  "
                                style={{ borderRadius: 0 }}>
                                <section
                                    className="avia_codeblock_section  avia_code_block_0" itemScope="itemscope"
                                    itemType="https://schema.org/CreativeWork">
                                    <div className="avia_codeblock " itemProp="text">
                                        <div className="stake-grid">
                                            <div className="stake">
                                                <img alt="Cosmos Network" height="54" src={logo1} width="54"/>
                                                <p>Cosmos{stats('cosmos')}</p>
                                                <a
                                                    href="https://restake.app/cosmoshub/cosmosvaloper1de7qx00pz2j6gn9k88ntxxylelkazfk3g8fgh9/stake"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                                <p className="auto_compound">auto compound</p>
                                            </div>
                                            <div className="stake">
                                                <img
                                                    alt="dydx" height="60"
                                                    src="./wp-content/themes/cosmic/img/dydx.svg"
                                                    style={{ height: '60px' }} width="54"/>
                                                <p>{variables[props.lang].dydx}{stats('dydx')}</p>
                                                <a
                                                    href="https://www.mintscan.io/dydx/validators/dydxvaloper1k2mlt4akhddh8gz9r3227ruuaestgdczx60vqk"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                                <p className="auto_compound">auto compound</p>
                                            </div>
                                            <div className="stake">
                                                <img
                                                    alt="injective" height="54"
                                                    src="./wp-content/themes/cosmic/img/injective.svg" width="54"/>
                                                <p>{variables[props.lang].injective}{stats('injective')}</p>
                                                <a
                                                    href="https://www.mintscan.io/injective/validators/injvaloper1fa558ytfnekl2zlmv7zpupam7zrcj2wkpqt90x"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                                <p className="auto_compound">auto compound</p>
                                            </div>
                                            <div className="stake">
                                                <img alt="Akash" height="54" src={logo2} width="54"/>
                                                <p>Akash{stats('akash')}</p>
                                                <a
                                                    href="https://www.mintscan.io/akash/validators/akashvaloper1ycv5n4fe0c5z22nr74t6v5g37glwc6vt6sjyft"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            <div className="stake">
                                                <img alt="Sentinel" height="54" src={logo3} width="54"/>
                                                <p>Sentinel{stats('sentinel')}</p>
                                                <a
                                                    href="https://www.mintscan.io/sentinel/validators/sentvaloper12w34cjj3swmzfcl0pmt3xud58z835h3w9xacpr"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            {/* <div className="stake"> */}
                                            {/*     <img alt="E-MONEY" height="54" src={logo4} width="54"/> */}
                                            {/*     <p>{variables[props.lang]['e_money']}{stats('e-money')}</p> */}
                                            {/*     <a */}
                                            {/*         href="https://wallet.e-money.com/earn/emoneyvaloper1538jg9jnqfpvgakh5t5namts4qa3hhpjh4dw0g" */}
                                            {/*         rel="noopener noreferrer" */}
                                            {/*         target="_blank"> */}
                                            {/*         {variables[props.lang].delegate} */}
                                            {/*     </a> */}
                                            {/* </div> */}
                                            <div className="stake">
                                                <img alt="Ki" height="54" src={logo5} width="54"/>
                                                <p>{variables[props.lang].k1}{stats('ki')}</p>
                                                <a
                                                    href="https://explorer.blockchain.ki/validator/kivaloper1z2hzm0mezfugegr2qrnn7luxn62t8pny7y7r9f"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            {/* <div className="stake"> */}
                                            {/*    <img alt="Osmosis" height="54" src={logo6} width="54"/> */}
                                            {/*    <p>{variables[props.lang].osmosis}{stats('osmosis')}</p> */}
                                            {/*    <a */}
                                            {/*        href="https://www.mintscan.io/osmosis/validators/osmovaloper1de7qx00pz2j6gn9k88ntxxylelkazfk3llxw6r" */}
                                            {/*        rel="noopener noreferrer" */}
                                            {/*        target="_blank"> */}
                                            {/*        {variables[props.lang].delegate} */}
                                            {/*    </a> */}
                                            {/* </div> */}
                                            {/* <div className="stake"> */}
                                            {/*    <img alt="Kusama" height="54" src={logo7} width="54"/> */}
                                            {/*    <p>{variables[props.lang].kusama}{stats('kusama')}</p> */}
                                            {/*    <a */}
                                            {/*        href="https://kusama.subscan.io/account/GZwGBCwor3GDBEpQMQEymWau8fhYuyp6LKAoSdYv1yKWaz4" */}
                                            {/*        rel="noopener noreferrer" */}
                                            {/*        target="_blank"> */}
                                            {/*        {variables[props.lang].delegate} */}
                                            {/*    </a> */}
                                            {/* </div> */}
                                            {/* <div className="stake"> */}
                                            {/*    <img alt="Terra" height="54" src={logo8} width="54"/> */}
                                            {/*    <p>{variables[props.lang].terra}{stats('terra')}</p> */}
                                            {/*    <a */}
                                            {/*        href="https://stake.id/#/validator/06EC3DDFB30C11F75491608707457FB128C34CD1 " */}
                                            {/*        rel="noopener noreferrer" */}
                                            {/*        target="_blank"> */}
                                            {/*        {variables[props.lang].delegate} */}
                                            {/*    </a> */}
                                            {/* </div> */}
                                            <div className="stake">
                                                <img
                                                    alt="persistence" height="54"
                                                    src="./wp-content/themes/cosmic/img/persistence.svg" width="54"/>
                                                <p>{variables[props.lang].persistence}{stats('persistence')}</p>
                                                <a
                                                    href="https://www.mintscan.io/persistence/validators/persistencevaloper1symey298hca5kcgkrtkdlu5cj5eqjulhxvn060"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            <div className="stake">
                                                <img
                                                    alt="juno" height="54"
                                                    src="./wp-content/themes/cosmic/img/juno.svg" width="54"/>
                                                <p>{variables[props.lang].juno}{stats('juno')}</p>
                                                <a
                                                    href="https://restake.app/juno/junovaloper1de7qx00pz2j6gn9k88ntxxylelkazfk3yugf8n/stake"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                                <p className="auto_compound">auto compound</p>
                                            </div>
                                            {/* <div className="stake"> */}
                                            {/*     <img */}
                                            {/*         alt="comdex" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/comdex.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].comdex}{stats('comdex')}</p> */}
                                            {/*     <a */}
                                            {/*         href="https://www.mintscan.io/comdex/validators/comdexvaloper18jlje9skzn0lgd209hh9h7cyyarydh0lf5hm27" */}
                                            {/*         rel="noopener noreferrer" */}
                                            {/*         target="_blank"> */}
                                            {/*         {variables[props.lang].delegate} */}
                                            {/*     </a> */}
                                            {/* </div> */}
                                            {/* <div className="stake"> */}
                                            {/*     <img */}
                                            {/*         alt="ixo" height="54" */}
                                            {/*         src="./wp-content/themes/cosmic/img/ixo.svg" width="54"/> */}
                                            {/*     <p>{variables[props.lang].ixo}{stats('ixo')}</p> */}
                                            {/*     <a */}
                                            {/*         href="https://blockscan.ixo.world/validator/ixovaloper16ceqk2hpwecg3h6ltrv8n8a8l8nxfnr9mk557h" */}
                                            {/*         rel="noopener noreferrer" */}
                                            {/*         target="_blank"> */}
                                            {/*         {variables[props.lang].delegate} */}
                                            {/*     </a> */}
                                            {/* </div> */}
                                            <div className="stake">
                                                <img
                                                    alt="tgrade" height="54"
                                                    src="./wp-content/themes/cosmic/img/tgrade.svg" width="54"/>
                                                <p>{variables[props.lang].tgrade}{stats('tgrade')}</p>
                                                <a
                                                    href="https://www.mintscan.io/tgrade/validators/tgrade102c8nrsw5wlezdkj9m6rvmx8rrlwf5n0t2yatd"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            <div className="stake">
                                                <img
                                                    alt="stride" height="54"
                                                    src="./wp-content/themes/cosmic/img/stride.svg" width="54"/>
                                                <p>{variables[props.lang].stride}{stats('stride')}</p>
                                                <a
                                                    href="https://www.mintscan.io/tgrade/validators/tgrade102c8nrsw5wlezdkj9m6rvmx8rrlwf5n0t2yatd"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            <div className="stake big_card">
                                                <img
                                                    alt="asset mantle" height="54"
                                                    src="./wp-content/themes/cosmic/img/asset_mantle.svg" width="54"/>
                                                <p>{variables[props.lang].asset_mantle}{stats('assetmantle')}</p>
                                                <a
                                                    href="https://www.mintscan.io/asset-mantle/validators/mantlevaloper1wfpspdprtm3tg9nwluxe9psmlmpfxq5x0h0g5m"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            <div className="stake big_card">
                                                <img
                                                    alt="omniflix" height="54"
                                                    src="./wp-content/themes/cosmic/img/omniflix.svg" width="54"/>
                                                <p>{variables[props.lang].omniflix}{stats('omniflix-network')}</p>
                                                <a
                                                    href="https://www.mintscan.io/omniflix/validators/omniflixvaloper1de7qx00pz2j6gn9k88ntxxylelkazfk3al79dq"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                                <p className="auto_compound">auto compound</p>
                                            </div>
                                            <div className="stake">
                                                <img
                                                    alt="kujira" height="54"
                                                    src="./wp-content/themes/cosmic/img/kujira.png" width="54"/>
                                                <p>{variables[props.lang].kujira}{stats('kujira')}</p>
                                                <a
                                                    href="https://www.mintscan.io/tgrade/validators/tgrade102c8nrsw5wlezdkj9m6rvmx8rrlwf5n0t2yatd"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            <div className="stake">
                                                <img
                                                    alt="neutron" height="54"
                                                    src="./wp-content/themes/cosmic/img/neutron.svg" width="54"/>
                                                <p>{variables[props.lang].neutron}{stats('neutron')}</p>
                                                <a
                                                    href="https://www.mintscan.io/tgrade/validators/tgrade102c8nrsw5wlezdkj9m6rvmx8rrlwf5n0t2yatd"
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {variables[props.lang].delegate}
                                                </a>
                                            </div>
                                            {/* <div className="stake"> */}
                                            {/*    <img */}
                                            {/*        alt="polkadex" height="54" */}
                                            {/*        src="./wp-content/themes/cosmic/img/polkadex.svg" width="54"/> */}
                                            {/*    <p>{variables[props.lang].polkadex}{stats('polkadex')}</p> */}
                                            {/*    /!* <a *!/ */}
                                            {/*    /!*    href="https://www.mintscan.io/juno/validators/esq2wMzjsKE4ZLwDMkWGyZixEPT3845muppgsrz2FDC7JZSdb" *!/ */}
                                            {/*    /!*    rel="noopener noreferrer" *!/ */}
                                            {/*    /!*    target="_blank"> *!/ */}
                                            {/*    /!*    {variables[props.lang].delegate} *!/ */}
                                            {/*    /!* </a> *!/ */}
                                            {/* </div> */}
                                            <div className="stake-total">
                                                <p>{variables[props.lang]['section2_total_heading']}</p>
                                                {props.inProgress
                                                    ? <DotsLoading/>
                                                    : props.data && props.data['total_usd_value']
                                                        ? <h2>${abbreviateNumber(props.data['total_usd_value'])}</h2>
                                                        : <h2>NA</h2>}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Section2.propTypes = {
    data: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        data: state.validators.stats.value,
        inProgress: state.validators.stats.inProgress,
    };
};

export default connect(stateToProps)(Section2);
