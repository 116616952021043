import { INFO_MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { SUBMIT_CONTACT_DETAILS_ERROR, SUBMIT_CONTACT_DETAILS_SUCCESS } from '../constants/contact';
import { VALIDATOR_STATS_FETCH_ERROR } from '../constants/validators';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case INFO_MESSAGE_SHOW:
    case SUBMIT_CONTACT_DETAILS_SUCCESS:
    case SUBMIT_CONTACT_DETAILS_ERROR:
    case VALIDATOR_STATS_FETCH_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
