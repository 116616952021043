import React from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Roadmap = (props) => {
    return (
        <div className="avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-86  el_after_av_one_full  el_before_av_section   container_wrap fullsize" id="roadmap"><div className="container">
            <div className="template-page content  av-content-full alpha units"><div className="post-entry post-entry-type-page post-entry-15"><div className="entry-content-wrapper clearfix">
                <div className="flex_column_table av-equal-height-column-flextable -flextable"><div className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-87  el_before_av_one_half  avia-builder-el-first  " style={{ borderRadius: 0 }}><div className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-88  avia-builder-el-no-sibling  " style={{ paddingBottom: '10x' }}>
                    <div className="av-subheading av-subheading_above  " style={{ fontSize: '15px' }}>
                        <p>{variables[props.lang]['rm_sub_heading']}</p>
                    </div>
                    <h1 className="av-special-heading-tag ">{variables[props.lang].roadmap}</h1>
                    <div className="special-heading-border"><div className="special-heading-inner-border"/></div>
                </div></div></div>
                {/* <div className="flex_column av_one_half  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-92  el_after_av_one_half  avia-builder-el-last  column-top-margin" style={{ borderRadius: 0 }}> */}
                {/*     <div className="av-special-heading av-special-heading-h5  blockquote modern-quote modern-centered  avia-builder-el-93  el_before_av_textblock  avia-builder-el-first  " style={{ paddingBottom: '10x' }}> */}
                {/*         <h5 className="av-special-heading-tag ">{variables[props.lang]['rm_q4_heading']}</h5> */}
                {/*         <div className="special-heading-border"><div className="special-heading-inner-border"/></div> */}
                {/*     </div> */}
                {/*     <section className="av_textblock_section "><div className="avia_textblock  "> */}
                {/*         <ul> */}
                {/*             /!* <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q4_text1']}</li> *!/ */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q3_text2']}</li> */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q3_text5']}</li> */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang].omniflix}</li> */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang].evmos}</li> */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang]['staking_dashboard']}</li> */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q3_text1']}</li> */}
                {/*             <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q3_text3']}</li> */}
                {/*             /!* <li style={{ textAlign: 'left' }}>{variables[props.lang].juno}</li> *!/ */}
                {/*             /!* <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q4_text4']}</li> *!/ */}
                {/*         </ul> */}
                {/*     </div></section> */}
                {/* </div> */}
                <div className="flex_column av_one_half  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding   avia-builder-el-92  el_after_av_one_half  avia-builder-el-last  column-top-margin" style={{ borderRadius: 0 }}>
                    <div className="av-special-heading av-special-heading-h5  blockquote modern-quote modern-centered  avia-builder-el-93  el_before_av_textblock  avia-builder-el-first  " style={{ paddingBottom: '10x' }}>
                        <h5 className="av-special-heading-tag ">{variables[props.lang]['rm_2022_heading']}</h5>
                        <div className="special-heading-border"><div className="special-heading-inner-border"/></div>
                    </div>
                    <section className="av_textblock_section "><div className="avia_textblock  ">
                        <ul>
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].injective}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].dydx_v4}</li> */}
                            <li style={{ textAlign: 'left' }}>{variables[props.lang].ethereum_2}</li>
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].evmos}</li> */}
                            {/* /!* <li style={{ textAlign: 'left' }}>{variables[props.lang].agoric}</li> *!/ */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].osmosis}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].quicksilver}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].gno_land}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang]['staking_dashboard']}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].axelar}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].nomic}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].anoma}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang]['roadmap_text']}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].althea}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang]['gravity_bridge']}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang].sommelier}</li> */}
                            {/* <li style={{ textAlign: 'left' }}>{variables[props.lang]['rm_q4_text3']}</li> */}
                        </ul>
                    </div></section>
                </div>
            </div></div></div>
        </div></div>
    );
};

Roadmap.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Roadmap;
