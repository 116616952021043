import React from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const YouTubePlayer = (props) => {
    let id = props.list && props.list.length && props.list[0];
    id = id && id.id && id.id.videoId;

    return (
        <div
            className="avia-section main_color avia-section-huge avia-no-border-styling  avia-full-stretch avia-bg-style-scroll  avia-builder-el-25  el_after_av_section  el_before_av_section   container_wrap fullsize"
            data-section-bg-repeat="stretch"
            id="youtube_player">
            <div className="container youtube_player_section">
                <div className="template-page content  av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-15">
                        <div className="entry-content-wrapper clearfix">
                            <div
                                className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-26  avia-builder-el-no-sibling  "
                                style={{ borderRadius: 0 }}>
                                <div
                                    className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-27  el_before_av_codeblock  avia-builder-el-first  "
                                    style={{
                                        paddingBottom: '10px',
                                        marginBottom: '4%',
                                    }}>
                                    <h1 className="av-special-heading-tag ">{variables[props.lang]['youtube_section_heading']}</h1>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <iframe className="youtube_player" height="600" src={`https://www.youtube.com/embed/${id}`} width="100%">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

YouTubePlayer.propTypes = {
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
};

export default YouTubePlayer;
