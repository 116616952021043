import React from 'react';
import background from '../../assets/wp-content/uploads/2021/06/cosmos-bg.jpg';
import background2 from '../../assets/wp-content/uploads/2021/06/cta-bg.jpg';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Section7 = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-huge avia-no-border-styling  avia-full-stretch avia-bg-style-scroll  avia-builder-el-43  el_after_av_section  el_before_av_section   container_wrap fullsize accordion_section"
            data-section-bg-repeat="stretch"
            id="accordion" style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundAttachment: 'scroll',
            }}>
            <div className="container">
                <div className="template-page content  av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-15">
                        <div className="entry-content-wrapper clearfix">
                            <div
                                className="flex_column_table av-equal-height-column-flextable av-break-at-tablet-flextable">
                                <div
                                    className="flex_column av_one_half  av-animated-generic bottom-to-top  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-44  el_before_av_one_half  avia-builder-el-first  "
                                    style={{ borderRadius: 0 }}>
                                    <div
                                        className="togglecontainer  av-elegant-toggle toggle_close_all  avia-builder-el-45  avia-builder-el-no-sibling ">
                                        <section className="av_toggle_section">
                                            <div className="single_toggle" data-tags="{All} " role="tablist"><p
                                                aria-controls="toggle-id-1" className="toggler "
                                                data-fake-id="#toggle-id-1" role="tab"
                                                tabIndex="0">{variables[props.lang]['s5_expansion1_heading']}<span className="toggle_icon">
                                                    <span className="vert_icon"/><span className="hor_icon"/></span>
                                            </p>
                                            <div className="toggle_wrap " id="toggle-id-1">
                                                <div className="toggle_content invers-color ">
                                                    <p>{variables[props.lang]['s5_expansion1_content']}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </section>
                                        <section className="av_toggle_section">
                                            <div className="single_toggle" data-tags="{All} " role="tablist"><p
                                                aria-controls="toggle-id-2" className="toggler "
                                                data-fake-id="#toggle-id-2" role="tab"
                                                tabIndex="0">{variables[props.lang]['s5_expansion2_heading']}<span className="toggle_icon">
                                                    <span className="vert_icon"/>
                                                    <span className="hor_icon"/></span></p>
                                            <div className="toggle_wrap " id="toggle-id-2">
                                                <div className="toggle_content invers-color ">
                                                    <p>{variables[props.lang]['s5_expansion2_content']}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </section>
                                        <section className="av_toggle_section">
                                            <div className="single_toggle" data-tags="{All} " role="tablist"><p
                                                aria-controls="toggle-id-3" className="toggler "
                                                data-fake-id="#toggle-id-3" role="tab"
                                                tabIndex="0">{variables[props.lang]['s5_expansion3_heading']}<span className="toggle_icon">
                                                    <span className="vert_icon"/><span className="hor_icon"/></span></p>
                                            <div className="toggle_wrap " id="toggle-id-3">
                                                <div className="toggle_content invers-color ">
                                                    <p>{variables[props.lang]['s5_expansion3_content']}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </section>
                                        <section className="av_toggle_section">
                                            <div className="single_toggle" data-tags="{All} " role="tablist"><p
                                                aria-controls="toggle-id-4" className="toggler "
                                                data-fake-id="#toggle-id-4" role="tab"
                                                tabIndex="0">{variables[props.lang]['s5_expansion4_heading']}<span className="toggle_icon">
                                                    <span className="vert_icon"/><span className="hor_icon"/></span>
                                            </p>
                                            <div className="toggle_wrap " id="toggle-id-4">
                                                <div className="toggle_content invers-color ">
                                                    <p>{variables[props.lang]['s5_expansion4_content']}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </section>
                                        <section className="av_toggle_section">
                                            <div className="single_toggle" data-tags="{All} " role="tablist"><p
                                                aria-controls="toggle-id-5" className="toggler "
                                                data-fake-id="#toggle-id-5" role="tab"
                                                tabIndex="0">{variables[props.lang]['s5_expansion5_heading']}<span className="toggle_icon">
                                                    <span className="vert_icon"/><span className="hor_icon"/></span></p>
                                            <div className="toggle_wrap " id="toggle-id-5">
                                                <div className="toggle_content invers-color ">
                                                    <p>{variables[props.lang]['s5_expansion5_content']}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <div className="av-flex-placeholder"/>
                                <div
                                    className="flex_column av_one_half  av-animated-generic bottom-to-top  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-46  el_after_av_one_half  el_before_av_one_full  "
                                    style={{ borderRadius: 0 }}>
                                    <div
                                        className="av-special-heading av-special-heading-h2  blockquote modern-quote  avia-builder-el-47  el_before_av_textblock  avia-builder-el-first  "
                                        style={{ paddingBottom: '10px' }}>
                                        <div
                                            className="av-subheading av-subheading_above  "
                                            style={{ fontSize: '15px' }}>
                                            <p>{variables[props.lang]['why_stake_with']}</p>
                                        </div>
                                        <h2 className="av-special-heading-tag ">{variables[props.lang]['cosmic_validator']}</h2>
                                        <div className="special-heading-border">
                                            <div className="special-heading-inner-border"/>
                                        </div>
                                    </div>
                                    <section className="av_textblock_section ">
                                        <div className="avia_textblock  ">
                                            <blockquote>
                                                <p style={{ textAlign: 'left' }}>{variables[props.lang]['section5_content']}</p>
                                            </blockquote>
                                            <p>{variables[props.lang]['section5_sub_content']}</p>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div
                                className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_div av-zero-column-padding first  avia-builder-el-49  el_after_av_one_half  avia-builder-el-last  column-top-margin"
                                id="cta-block" style={{
                                    background: `url(${background2}) center center no-repeat`,
                                    borderRadius: 0,
                                }}>
                                <div
                                    className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-50  el_before_av_textblock  avia-builder-el-first  "
                                    style={{ paddingBottom: '10px' }}>
                                    <div className="av-subheading av-subheading_above  " style={{ fontSize: '15px' }}>
                                        <p>{variables[props.lang]['section5_card_sub_heading']}</p>
                                    </div>
                                    <h1 className="av-special-heading-tag ">{variables[props.lang]['section5_card_heading']}</h1>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border"/>
                                    </div>
                                </div>
                                <section className="av_textblock_section ">
                                    <div className="avia_textblock  ">
                                        <p style={{ textAlign: 'center' }}>{variables[props.lang]['section5_card_content']}</p>
                                    </div>
                                </section>
                                <div
                                    className="avia-button-wrap avia-button-center  avia-builder-el-52  el_after_av_textblock  avia-builder-el-last ">
                                    <a className="avia-button  avia-color-light   avia-icon_select-no avia-size-large avia-position-center " href="#contact" itemProp="url"><span
                                        className="avia_iconbox_title">{variables[props.lang]['talk_to_manager']}</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Section7.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Section7;
