import Axios from 'axios';
import { VALIDATOR_STATS_URL } from '../constants/url';
import {
    VALIDATOR_STATS_FETCH_ERROR,
    VALIDATOR_STATS_FETCH_IN_PROGRESS,
    VALIDATOR_STATS_FETCH_SUCCESS,
} from '../constants/validators';

const fetchValidatorStatsInProgress = () => {
    return {
        type: VALIDATOR_STATS_FETCH_IN_PROGRESS,
    };
};

const fetchValidatorStatsSuccess = (value) => {
    return {
        type: VALIDATOR_STATS_FETCH_SUCCESS,
        value,
    };
};

const fetchValidatorStatsError = (message) => {
    return {
        type: VALIDATOR_STATS_FETCH_ERROR,
        message,
    };
};

export const fetchValidatorStats = () => (dispatch) => {
    dispatch(fetchValidatorStatsInProgress());

    Axios.get(VALIDATOR_STATS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchValidatorStatsSuccess(res.data && res.data.result));
        })
        .catch(() => {
            dispatch(fetchValidatorStatsError('Failed!'));
        });
};
