import Axios from 'axios';
import { YOUTUBE_LIST_URL } from '../constants/url';
import { VIDEOS_LIST_FETCH_ERROR, VIDEOS_LIST_FETCH_IN_PROGRESS, VIDEOS_LIST_FETCH_SUCCESS } from '../constants/player';

const fetchVideoListInProgress = () => {
    return {
        type: VIDEOS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchVideoListSuccess = (value) => {
    return {
        type: VIDEOS_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchVideoListError = (message) => {
    return {
        type: VIDEOS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchVideoList = () => (dispatch) => {
    dispatch(fetchVideoListInProgress());

    Axios.get(YOUTUBE_LIST_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchVideoListSuccess(res.data && res.data.items));
        })
        .catch(() => {
            dispatch(fetchVideoListError('Failed!'));
        });
};
