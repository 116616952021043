import { combineReducers } from 'redux';
import { VIDEOS_LIST_FETCH_ERROR, VIDEOS_LIST_FETCH_IN_PROGRESS, VIDEOS_LIST_FETCH_SUCCESS } from '../constants/player';

const videos = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case VIDEOS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case VIDEOS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case VIDEOS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    videos,
});
