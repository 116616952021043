import React from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Footer = (props) => {
    return (
        <footer className="container_wrap socket_color" id="socket" role="contentinfo">
            <div className="container">
                <span className="copyright">{variables[props.lang]['footer_content']}</span>
                <section className="avia_codeblock_section  avia_code_block_9"><div className="avia_codeblock "><div className="powered">
                    <div><img alt="Powered by Akash" height="32" src="./wp-content/themes/cosmic/img/powered.svg" width="260" /></div>
                </div></div></section>
                <ul className="noLightbox social_bookmarks icon_count_3">
                    <li className="social_bookmarks_twitter av-social-link-twitter social_icon_1"><a aria-hidden="false" aria-label="Link to Twitter" data-av_icon="" data-av_iconfont="entypo-fontello" href="https://twitter.com/CosmicValidator" rel="noopener noreferrer" target="_blank" title="Twitter"><span className="avia_hidden_link_text">Twitter</span></a></li>
                    <li className="social_bookmarks_medium av-social-link-medium social_icon_2"><a aria-hidden="false" aria-label="Link to Medium" data-av_icon="" data-av_iconfont="cosmic" href="https://medium.com/CosmicValidator" rel="noopener noreferrer" target="_blank" title="Medium"><span className="avia_hidden_link_text">Medium</span></a></li>
                    <li className="social_bookmarks_telegram av-social-link-telegram social_icon_3"><a aria-hidden="false" aria-label="Link to Telegram" data-av_icon="" data-av_iconfont="cosmic" href="https://t.me/CosmicValidator" rel="noopener noreferrer" target="_blank" title="Telegram"><span className="avia_hidden_link_text">Telegram</span></a></li>
                    <li className="social_bookmarks_youtube av-social-link-youtube social_icon_4"><a aria-hidden="false" aria-label="Link to Youtube" data-av_icon="" data-av_iconfont="entypo-fontello" href="https://www.youtube.com/channel/UCX7kHUiacI6ycHF8Wd8mGmw" rel="noopener noreferrer" target="_blank" title="Youtube"><span className="avia_hidden_link_text">YouTube</span></a></li>
                </ul>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Footer;
