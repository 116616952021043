import React from 'react';
import background from '../../../assets/wp-content/uploads/2021/06/cosmos-bg.jpg';
import Form from './Form.js';
import './index.css';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';

const Contact = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-95  el_after_av_section  avia-builder-el-last   container_wrap fullsize contact_section" data-section-bg-repeat="no-repeat" id="contact" style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
                backgroundAttachment: 'scroll',
            }}>
            <div className="container">
                <div className="template-page content  av-content-full alpha units"><div className="post-entry post-entry-type-page post-entry-15"><div className="entry-content-wrapper clearfix">
                    <div className="flex_column_table av-equal-height-column-flextable -flextable"><div className="flex_column av_one_full  av-animated-generic bottom-to-top  flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-96  el_before_av_one_full  avia-builder-el-first  " style={{ borderRadius: 0 }}>
                        <div className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-97  el_before_av_textblock  avia-builder-el-first  " style={{ paddingBottom: '10px' }}>
                            <div className="av-subheading av-subheading_above  " style={{ fontSize: '15px' }}>
                                <p>{variables[props.lang]['contact_sub_heading']}</p>
                            </div>
                            <h1 className="av-special-heading-tag ">{variables[props.lang]['contact_heading']}</h1>
                            <div className="special-heading-border"><div className="special-heading-inner-border" /></div>
                        </div>
                        <section className="av_textblock_section ">
                            <div className="avia_textblock contact ">
                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['contact_content']}</p>
                                <Form />
                                {/* <a className="avia-button  avia-color-light   avia-icon_select-no avia-size-large avia-position-center" href="mailto:youremail@here.com" style={{ margin: '30px auto', display: 'block', maxWidth: '300px' }}>CONTACT US</a> */}
                            </div>
                        </section></div></div>
                </div>
                </div>
                </div>
            </div>
        </div>
    );
};

Contact.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Contact;
