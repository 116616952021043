import React from 'react';
import video from '../../assets/wp-content/themes/cosmic/img/staking.mp4';
import background from '../../assets/wp-content/uploads/2021/06/cosmos-bg.jpg';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Section5 = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-huge avia-no-border-styling  avia-full-stretch avia-bg-style-scroll  avia-builder-el-30  el_after_av_section  el_before_av_section   container_wrap fullsize"
            data-section-bg-repeat="stretch"
            id="staking" style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundAttachment: 'scroll',
            }}>
            <div className="container">
                <div className="template-page content  av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-15">
                        <div className="entry-content-wrapper clearfix">
                            <div
                                className="flex_column_table av-equal-height-column-flextable av-break-at-tablet-flextable">
                                <div
                                    className="flex_column av_one_half  av-animated-generic bottom-to-top  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-31  el_before_av_one_half  avia-builder-el-first  "
                                    style={{ borderRadius: 0 }}>
                                    <section className="avia_codeblock_section  avia_code_block_6">
                                        <div className="avia_codeblock ">
                                            <video autoPlay loop muted playsInline>
                                                <source src={video} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </section>
                                </div>
                                <div className="av-flex-placeholder"/>
                                <div
                                    className="flex_column av_one_half  av-animated-generic bottom-to-top  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-33  el_after_av_one_half  avia-builder-el-last  "
                                    style={{ borderRadius: 0 }}>
                                    <div
                                        className="av-special-heading av-special-heading-h2  blockquote modern-quote  avia-builder-el-34  el_before_av_textblock  avia-builder-el-first  "
                                        style={{ paddingBottom: '10px' }}>
                                        <h2 className="av-special-heading-tag ">{variables[props.lang]['what_is_staking']}</h2>
                                        <div className="special-heading-border">
                                            <div className="special-heading-inner-border"/>
                                        </div>
                                    </div>
                                    <section className="av_textblock_section ">
                                        <div className="avia_textblock  ">
                                            <p style={{ textAlign: 'left' }}>{variables[props.lang]['section4_row1_content']}</p>
                                        </div>
                                    </section>
                                    <div
                                        className="avia-button-wrap avia-button-left  avia-builder-el-36  el_after_av_textblock  avia-builder-el-last ">
                                        <a className="avia-button  avia-color-light   avia-icon_select-no avia-size-large avia-position-left " href="#validator" itemProp="url"><span
                                            className="avia_iconbox_title">{variables[props.lang]['stake_with_cosmic_validator']}</span></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Section5.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Section5;
