const variables = {
    en: {
        home: 'Home',
        cosmic_compass_explorer: 'Cosmic Compass Explorer',
        blog: 'Blog',
        menu: 'Menu',
        stake_with_us: 'Stake with us',
        section1_heading1: 'Be part of the',
        section1_heading2: 'decentralization movement',
        section1_sub_heading: 'and earn your keep.',
        section1_content: 'Cosmic Validator offers staking opportunities for your cryptocurrency assets that are safe, secure and easy to understand. Using our expertise we also help our stakers to realize profits and participate in building curated projects to their full potential.',
        delegate: 'Delegate',
        section2_total_heading: 'Currently Staked to Cosmic Validator',
        section2_sub_heading: 'Selected by Stride and the Interchain Foundation (ICF)',
        section2_heading: 'Cosmic Validator!',
        section2_content: 'Cosmic Validator has been selected by Stride and the Interchain Foundation (ICF) as a top contributor validator. We are proud to have earned the trust of Stride and the Interchain Foundation (ICF) and we will continue supporting the Cosmos ecosystem.',
        selected_by_icf: 'Selected by ICF',
        selected_by_stride: 'Selected by Stride',
        announcement_button: 'SEE ANNOUNCEMENT',
        read_the_tweet: 'READ THE TWEET',
        section2_card1_heading: 'ROCK SOLID',
        section2_card1_content: 'Our infrastructure has been running with no incidence and has performed admirably in terms of staking speed. We continue to improve our backend performance to be one of the most technically proficient validators around.',
        section2_card2_heading: 'SAFE AND SECURE',
        section2_card2_content: 'Our cloud team designed, built and maintained several world class blockchain projects, hence Cosmic Validator is confident of its technical skills to maintain a solid and robust infrastructure for our validator nodes.',
        section2_card3_heading: 'REAL EXPERTS',
        section2_card3_content: 'Our team are the most well-known and passionate figures in their fields. With our combined perspective and knowledge, we identify the best networks to stake in. With us, you stake in the most optimal opportunity.',
        section3_heading: 'Validator on the best projects',
        cosmos: 'Cosmos Network',
        akash: 'Akash Network',
        sentinel: 'Sentinel Network',
        e_money: 'E-money',
        terra: 'Terra',
        kusama: 'Kusama',
        osmosis: 'Osmosis',
        k1: 'Ki',
        bsc: 'BSC',
        polkadot: 'Polkadot',
        near: 'Near',
        agoric: 'Agoric',
        tgrade: 'Tgrade',
        persistence: 'Persistence',
        regen: 'Regen',
        omniflix: 'OmniFlix Network',
        comdex: 'Comdex',
        ixo: 'Ixo',
        asset_mantle: 'Asset Mantle',
        juno: 'Juno',
        polkadex: 'Polkadex',
        network: 'Network',
        coming_soon: 'Coming Soon',
        what_is_staking: 'What is staking?',
        section4_row1_content: 'Most of the newer blockchain networks are being secured by a concept called Proof of Stake (PoS) instead of Proof of Work (PoW) wherein people stake their tokens in a validator. Once staked, you get part of the rewards the validator gets when it validates transactions in that blockchain network essentially increasing your tokens. This is a more energy efficient alternative to how Bitcoin is being secured and is found in newer projects like Cosmos.',
        stake_with_cosmic_validator: 'STAKE WITH COSMIC VALIDATOR',
        staking_as: 'Staking as',
        passive_income: 'Passive Income',
        section4_row2_content: 'The decentralization movement led by Bitcoin spread the work from centralized financial parties like governments, banks or law firms to anyone with a powerful computer. As such the payment for work went to those who participate using their resources.',
        section4_row2_sub_content: 'This shift led to miners who earned billions of dollars worth because they took the chance early. With the proof of stake mechanism supplanting proof of work, a new window has opened up to become an early supporter and unlock a new way to get passive income which may be the path to your financial freedom.',
        why_stake_with: 'Why stake with',
        cosmic_validator: 'Cosmic Validator?',
        section5_content: 'Cosmic Validator is an independent validator staking service that is unattached to any major entity in crypto, we are as indie as it gets. This means we further decentralize the networks we support.',
        section5_sub_content: 'Moreover, two of our co-founders - Hector and Eric - are two of the most reputable and hardcore crypto supporters in the Cosmos, Sentinel and OTC communities. Plenty other reasons are expounded further why you should stake in Cosmic Validator:',
        s5_expansion1_heading: 'SECURED',
        s5_expansion1_content: 'Our devops have a long history of securing security focused products in the decentralized world.',
        s5_expansion2_heading: 'A GLOBAL FAMILY',
        s5_expansion2_content: 'With a team that is from France, India, Switzerland and the Philippines – we live and breathe decentralization not just in tech but in our internal culture too! Join the CV family and be part of a global movement!',
        s5_expansion3_heading: 'GEEKIEST STAKERS',
        s5_expansion3_content: 'Our team knows everything there is to know about picking good projects and how good the staking model is. We have been an active force in the early days of the leading staking projects in crypto.',
        s5_expansion4_heading: 'OTC ACCESS',
        s5_expansion4_content: 'Our team is one of the most trusted OTC parties around. Sourced and assisted over high volumes of various digital assets since 2017.',
        s5_expansion5_heading: 'EXPERT CURATION',
        s5_expansion5_content: 'Our team has been a fixture in leading Proof of Stake communities such as Cosmos, Polkadot, Sentinel and others. We have also collectively invested in over 100 blockchain projects so we are very focused on long term viability.',
        section5_card_sub_heading: 'WANT TO STAKE',
        section5_card_heading: 'A LARGE AMOUNT?',
        section5_card_content: 'We assist in purchasing tokens to stake!',
        talk_to_manager: 'TALK TO OUR TOKEN ACQUISITION MANAGER',
        s6_row1_heading: '$Cosmic token',
        s6_row1_content: 'Cosmic validator is a validator for the people, by the people. The Cosmic token is what will power our community and our growth as we become one of the most decentralized validators in the space.',
        s6_row1_sub_content: 'Rewarded to those who delegate through our special governance, COSMIC token will not have any VC or any private sale and will be 100% farmed by delegating through us or buying from the market.',
        s6_row2_sub_heading: 'Dashboard of your',
        s6_row2_heading: 'STAKING empire!',
        s6_row2_sub_content: 'Track your ROI (return on investment) on our upcoming dashboard that will also elevate your staking knowledge along with us.',
        section7_heading: 'Our Experts',
        section7_content: 'Our team are mainstays and passionate players in various crypto communities and armed with intimate knowledge of how to operate a great validator staking service. You won’t find a more hardcore team than us.',
        s7_card1_name: 'Hector',
        s7_card1_content: 'Co-founder (PwC & Bank Frick Blockchain Labs)',
        s7_card2_name: 'Eric Su',
        s7_card2_content: 'Co-founder (Exnetwork)',
        s7_card3_name: 'Cosmic Tech Labs (CTL)',
        s7_card3_position: 'Co-founder & Technology Partner',
        s7_card3_content: '(parent company of OmniFlix Network)',
        s7_card4_name: 'Estelle',
        s7_card4_content: 'Community manager',
        s7_card5_name: 'Anita',
        s7_card5_content: 'Video designer, ex-TikTok',
        partners: 'Partners',
        name: 'Name',
        email_address: 'Email Address',
        rm_sub_heading: 'THIS IS OUR',
        roadmap: 'Roadmap',
        rm_q3_heading: 'Q3 2021',
        rm_q3_text1: 'BSC',
        rm_q3_text2: 'Polkadot',
        rm_q3_text3: 'Near',
        rm_q3_text4: 'Agoric',
        rm_q3_text5: 'Tgrade',
        rm_q3_text6: '$COSMIC testnets',
        rm_q4_heading: 'Q4 2021',
        rm_2022_heading: '2023',
        rm_q4_text1: '$COSMIC',
        rm_q4_text3: 'Sentries on Akash',
        rm_q4_text4: 'Regen Network',
        althea: 'Althea',
        gravity_bridge: 'Gravity Bridge',
        evmos: 'Evmos',
        quicksilver: 'Quicksilver',
        gno_land: 'Gno.land',
        axelar: 'Axelar',
        nomic: 'Nomic',
        sommelier: 'Sommelier',
        anoma: 'Anoma',
        injective: 'Injective',
        dydx_v4: 'dYdX v4',
        dydx: 'dYdX',
        kujira: 'Kujira',
        stride: 'Stride',
        neutron: 'Neutron',
        ethereum_2: 'Ethereum 2.0',
        roadmap_text: 'Improve our Cosmos ecosystem news videos with Estelle',
        staking_dashboard: 'Staking Dashboard',
        contact_sub_heading: 'Need experts to run a validator node for a',
        contact_heading: 'project you are bullish on?',
        contact_content: 'We are available to help you! We study the requirements and help you to navigate the rules of running a validator and optimize your server costs. Fill up the form below and let’s get cracking!',
        message: 'Message',
        submit: 'Submit',
        footer_content: 'Cosmic Validator | Copyright 2021',
        youtube_section_heading: 'Cosmos ecosystem news with Estelle',
    },
};

export default variables;
