import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import TextField from '../../../components/TextField';
import { Button } from '@material-ui/core';
import CircularProgress from '../../../components/CircularProgress';
import { isValidEmail } from '../../../utils/validation';
import { setComment, setEmail, setName, submitContactDetails } from '../../../actions/contact';

const Form = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleSend();
        }
    };

    const handleSend = () => {
        const valid = isValidEmail(props.email);
        props.setEmail(props.email, valid);
        props.setName(props.name, props.name !== '');
        props.setComment(props.comment, props.comment !== '');

        const data = {
            name: props.name,
            emailAddress: props.email,
            message: props.comment,
        };

        if (valid && props.name !== '' && props.comment !== '') {
            props.submitContactDetails(data);
        }
    };

    const disable = props.inProgress;

    return (
        <form
            noValidate
            autoComplete="off"
            className="form"
            onKeyPress={handleKeyPress}
            onSubmit={(e) => {
                e.preventDefault();
            }}>
            <div className="row">
                <TextField
                    error={!props.nameValid}
                    errorText={!props.nameValid ? 'Name Should not be empty' : ''}
                    id="name-text-field"
                    name="name"
                    placeholder={variables[props.lang].name}
                    value={props.name}
                    onChange={(value) => props.setName(value, true)} />
                <TextField
                    error={!props.emailValid}
                    errorText={!props.emailValid ? 'Please enter valid email address' : ''}
                    id="email-text-field"
                    name="email"
                    placeholder={variables[props.lang]['email_address']}
                    value={props.email}
                    onChange={(value) => props.setEmail(value, true)} />
            </div>
            <TextField
                multiline
                error={!props.commentValid}
                errorText={!props.commentValid ? 'Message Should not be empty' : ''}
                id="comment-text-field"
                name="comment"
                placeholder={variables[props.lang].message}
                value={props.comment}
                onChange={(value) => props.setComment(value, true)} />
            <div className="button_div">
                <Button
                    className="primary_button"
                    disabled={disable}
                    onClick={handleSend}>
                    {variables[props.lang].submit}
                </Button>
                {disable && <CircularProgress />}
            </div>
        </form>
    );
};

Form.propTypes = {
    comment: PropTypes.string.isRequired,
    commentValid: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    emailValid: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nameValid: PropTypes.bool.isRequired,
    setComment: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    submitContactDetails: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        email: state.contact.email.value,
        emailValid: state.contact.email.valid,
        name: state.contact.name.value,
        nameValid: state.contact.name.valid,
        comment: state.contact.comment.value,
        commentValid: state.contact.comment.valid,
        inProgress: state.contact.contactInProgress,
    };
};

const actionsToProps = {
    setName,
    setEmail,
    setComment,
    submitContactDetails,
};

export default connect(stateToProps, actionsToProps)(Form);
