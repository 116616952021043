import {
    AUTO_HIDE_SET,
    COMMENT_SET,
    EMAIL_SET,
    NAME_SET, SUBMIT_CONTACT_DETAILS_ERROR,
    SUBMIT_CONTACT_DETAILS_IN_PROGRESS, SUBMIT_CONTACT_DETAILS_SUCCESS,
    SUBSCRIBE_MAIL_SET,
    SUBSCRIBE_NAME_SET,
} from '../constants/contact';
import { CONTACT_DETAILS_URL } from '../constants/url';
import Axios from 'axios';

export const setName = (value, valid) => {
    return {
        type: NAME_SET,
        value,
        valid,
    };
};

export const setEmail = (value, valid) => {
    return {
        type: EMAIL_SET,
        value,
        valid,
    };
};

export const setComment = (value, valid) => {
    return {
        type: COMMENT_SET,
        value,
        valid,
    };
};

export const setSubscribeName = (value, valid) => {
    return {
        type: SUBSCRIBE_NAME_SET,
        value,
        valid,
    };
};

export const setSubscribeMail = (value, valid) => {
    return {
        type: SUBSCRIBE_MAIL_SET,
        value,
        valid,
    };
};

const submitContactDetailsInProgress = () => {
    return {
        type: SUBMIT_CONTACT_DETAILS_IN_PROGRESS,
    };
};

const submitContactDetailsSuccess = (message) => {
    return {
        type: SUBMIT_CONTACT_DETAILS_SUCCESS,
        message,
    };
};

const submitContactDetailsError = (message) => {
    return {
        type: SUBMIT_CONTACT_DETAILS_ERROR,
        message,
    };
};

export const submitContactDetails = (data) => (dispatch) => {
    dispatch(submitContactDetailsInProgress());

    Axios.post(CONTACT_DETAILS_URL, data, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then(() => {
            dispatch(submitContactDetailsSuccess('Success'));
        })
        .catch(() => {
            dispatch(submitContactDetailsError('Failed!'));
        });
};

export const setAutoHide = () => {
    return {
        type: AUTO_HIDE_SET,
    };
};
