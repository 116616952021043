import { combineReducers } from 'redux';
import { ACTIVE_TAB_SET, HIDE_SIDE_BAR_SET, SHOW_SIDE_BAR_SET } from '../constants/navBar';

const show = (state = false, action) => {
    switch (action.type) {
    case SHOW_SIDE_BAR_SET:
        return true;
    case HIDE_SIDE_BAR_SET:
        return false;
    default:
        return state;
    }
};

const activeTab = (state = 'home', action) => {
    if (action.type === ACTIVE_TAB_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    show,
    activeTab,
});
