import React from 'react';
import hero from '../../assets/wp-content/themes/cosmic/img/hero.mp4';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Section1 = (props) => {
    return (
        <div
            className="avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-0  el_before_av_section  avia-builder-el-first   av-minimum-height av-minimum-height-100  av-section-with-video-bg container_wrap fullsize"
            data-av_minimum_height_pc="100"
            data-section-video-ratio="16:9" id="hero">
            <div
                className="avia-slideshow avia-slideshow-1  av-default-height-applied avia-slideshow-featured   av-section-video-bg avia-slide-slider "
                data-animation="slide" data-autoplay="false" data-autoplay_stopper=""
                data-bg_slider="false" data-conditional_play="" data-control_layout="" data-css_id=""
                data-custom_markup=""
                data-default-height="28.666666666667" data-el_id="" data-handle="" data-ids="" data-image_attachment=""
                data-interval="5" data-lazy_loading="disabled" data-lightbox_size="large" data-min_height="0px"
                data-perma_caption="" data-scroll_down="" data-size="featured"
                data-slide_height=""
                data-video_counter="1"
                itemProp="image" itemScope="itemscope" itemType="https://schema.org/ImageObject">
                <ul className="avia-slideshow-inner " style={{ paddingBottom: '28.666666666667%' }}>
                    <li
                        className=" av-video-slide  av-video-service-html5  av-hide-video-controls av-mute-video av-loop-video av-single-slide slide-1 "
                        data-controls="disabled" data-disable-autoplay=""
                        data-loop="1" data-mute="aviaTBaviaTBvideo_mute"
                        data-video-ratio="3.4883720930233">
                        <div className="avia-slide-wrap " data-rel="slideshow-1">
                            <div className="av-click-overlay"/>
                            <video
                                autoPlay loop muted className="avia_video" id="player_15_156035942_1904460696"
                                preload="metadata">
                                <source src={hero} type="video/mp4"/>
                            </video>
                            <div className="av-click-to-play-overlay">
                                <div className="avia_playpause_icon"/>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="av-section-color-overlay-wrap">
                <a
                    aria-hidden="true" className="scroll-down-link " data-av_icon="" data-av_iconfont="entypo-fontello"
                    href="#next-section"
                    title=""/>
                <div className="container">
                    <main
                        className="template-page content  av-content-full alpha units" itemProp="mainContentOfPage"
                        role="main">
                        <div className="post-entry post-entry-type-page post-entry-15">
                            <div className="entry-content-wrapper clearfix">
                                <div
                                    className="flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-1  el_before_av_one_full  avia-builder-el-first  "
                                    style={{ borderRadius: 0 }}>
                                    <div
                                        className="av-special-heading av-special-heading-h1  blockquote modern-quote modern-centered  avia-builder-el-2  avia-builder-el-no-sibling  "
                                        style={{ paddingBottom: '10px' }}>
                                        <h1 className="av-special-heading-tag " itemProp="headline">
                                            {variables[props.lang]['section1_heading1']}<br/>{variables[props.lang]['section1_heading2']}</h1>
                                        <div
                                            className="av-subheading av-subheading_below  "
                                            style={{ fontSize: '15px' }}>
                                            <p>{variables[props.lang]['section1_sub_heading']}</p>
                                        </div>
                                        <div className="special-heading-border">
                                            <div className="special-heading-inner-border"/>
                                        </div>
                                    </div>
                                    <div
                                        className="flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-3  el_after_av_one_full  avia-builder-el-last  column-top-margin"
                                        style={{ borderRadius: 0 }}>
                                        <section
                                            className="av_textblock_section " itemScope="itemscope"
                                            itemType="https://schema.org/CreativeWork">
                                            <div className="avia_textblock  " itemProp="text">
                                                <p style={{ textAlign: 'center' }}>{variables[props.lang]['section1_content']}</p>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

Section1.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Section1;
